// $offset-iframe: -2.5rem;
$offset-iframe: 0rem;

.footer {
  min-height: $footer-height;
  overflow: hidden;
  // override header menu styles
  .headerMenu {
    &Item {
      font-weight: 400 !important;
      border: none !important;
      &Wrapper {
        margin-bottom: 1rem !important;
      }
    }
  }

  .fbEmbed {
    min-height: calc($footer-height - 12rem);
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    iframe {
      height: calc(100% + $offset-iframe * -1 - 0.5rem);
      position: absolute;
      top: $offset-iframe;
      bottom: 0;
    }
  }
}
