.header {
  &Menu {
    &ItemActive {
      margin-bottom: -1px;
      border-bottom: 2px solid $secondary;
    }
    &Button {
      min-width: 32px;
      i {
        font-size: 1.5rem;
      }
    }
  }

  &Parent {
    overflow: hidden;
    position: sticky;
    top: 0;
    z-index: 1020;
    background-color: $light;
  }
  &Wrapper {
    min-height: 4rem;
  }
  &Offcanvas {
    background-color: $light;
    opacity: $menu-opacity;
    border: none !important;
    width: 100%;
  }
  &LogoButton {
    position: relative;

    img {
      width: 100%;
      height: auto;
    }

    &::after {
      content: '';
      user-select: none;
      pointer-events: none;
      width: 200vw;
      height: 1px;
      background-color: $secondary;
      position: absolute;
      left: -110vw;
      bottom: 22%;
      z-index: -1;
    }
  }
}
