.centerPhoto {
  width: 100vw !important;
  margin-left: calc(-50vw + 50%);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  &Description {
    font-size: 1rem;
    bottom: -0.75rem;
    right: 1rem;
    text-shadow: $black 1px 0 1px;
  }
}

@include media-breakpoint-up(md) {
  .centerPhoto {
    width: 100% !important;
    margin-left: 0 !important;
    &Description {
      font-size: 0.75rem;
    }
  }
}
