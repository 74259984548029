.teachersImageMaxWidth {
  max-width: 50%;
}

@include media-breakpoint-up(md) {
  .teachersImageMaxWidth {
    max-width: 35%;
  }
}

@include media-breakpoint-up(lg) {
  .teachersImageMaxWidth {
    max-width: 100%;
  }
}
