@import './colors';
@import './vars';

.carousel-indicators {
  gap: 15px;
  bottom: $indicators-padding;
  button[data-bs-target] {
    margin: 0;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: 1px solid $light;
    opacity: 1;
    background: transparent;
    flex-shrink: 0;
    &.active {
      background: $light;
    }
  }
}

.carousel-dark {
  .carousel-indicators {
    gap: 15px;
    button[data-bs-target] {
      margin: 0;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      border: 1px solid $primary;
      opacity: 1;
      background: transparent;
      &.active {
        background: $primary;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .carousel-indicators {
    bottom: 0;
  }
}

@include media-breakpoint-down(md) {
  .sliderIcon {
    display: none;
  }
}
.sliderWrapper {
  position: relative;

  .slider {
    &Photo {
      &Wrapper {
        min-height: $slider-photo-min-height;
        max-height: $slider-photo-min-height;

        .responsiveImage {
          object-fit: cover;
          width: 100%;
          min-height: $slider-photo-min-height;
          max-height: $slider-photo-min-height;
        }
      }
    }
    &Bumper {
      position: absolute;
      bottom: 0;
    }
  }

  &Text {
    min-height: 70vh;

    .sliderIcon {
      font-size: 2.5rem;
      color: #000;
    }
    .carousel-indicators {
      bottom: 0;
    }
  }
}
