$indicators-padding: 50px;

// slider dims
$slider-photo-max-height: 675px;
$slider-photo-min-height: 69vh;
$slider-text-min-height: 50vh;

// offscanvas
$menu-opacity: 0.95;

// image separator
$image-height: 338px;

// embed youtube
$embed-min-height: 221px;

// footer
$footer-height: 752px;
