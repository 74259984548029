.roundedTop {
  border-radius: 45px 45px 0 0;
}

.maps {
  position: relative;
  .route-btn {
    position: absolute;
    top: 1rem;
    left: 10px;
    z-index: 666;
    background: white;
    margin: 10px;
    padding: 0.5rem;
    border: 0;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    border-radius: 2px;
    cursor: pointer;
    user-select: none;
    * {
      text-decoration: none;
    }
  }
}
.embed {
  &MinHeight {
    min-height: $embed-min-height;
  }

  &MinHeightDoubled {
    max-height: calc(2 * $embed-min-height + 3rem);
  }

  &HideControls {
    height: calc(4 * $embed-min-height + 3rem);
    margin-top: calc($embed-min-height * -1);
  }
}

.mainTime {
  font-size: 1.25rem;
  span {
    font-size: 1.5em;
  }
}
.secondaryText {
  font-size: 0.725rem;
}

.tj {
  text-align: justify;
}

.tint {
  background-color: rgba(0, 0, 0, 0.5);
}

.topLeft {
  top: 0;
  left: 0;
}

.fsText {
  font-size: 1rem;
}

.bumper {
  background-color: $light;
  width: 100%;
  height: $indicators-padding;
  margin-bottom: -1px;
  position: relative;
}
.tinted {
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $black;
    opacity: 0.5;
    z-index: 1;
  }
}

@include media-breakpoint-up(md) {
  .roundedTop {
    border-radius: 0;
  }
  .secondaryText {
    font-size: 1rem;
  }
}
@include media-breakpoint-up(lg) {
  .fw-lg-bold {
    font-weight: bold;
  }
}
