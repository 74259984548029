.imageSeparator {
  min-height: $image-height;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  position: relative;
  .responsiveImage {
    object-fit: cover;
    width: 100%;
    min-height: $image-height;
  }
}

// top bumper
@include media-breakpoint-up(lg) {
  .imageSeparator {
    position: relative;
    min-height: calc(1.5 * $image-height);
    .responsiveImage {
      min-height: calc(1.5 * $image-height);
    }
    &::after {
      position: absolute;
      top: -1px;
      content: '';
      height: 60px;
      max-width: 1140px;
      width: 80%;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      background-color: $light;
    }
  }
}
