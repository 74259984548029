$primary: #222;
$secondary: #ce162b;
$light: #fff;
$yellow: #ffd451;
$gray: #ebebeb;
$dark: #ebebeb;
$black: #343434;
$info: #b8d8ff;
$warning: #6f9ed7;
$danger: #ffd451;
