@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/vars';

html,
body {
  max-width: 100%;
  scroll-behavior: smooth;
}
* {
  scroll-margin-top: 6.75rem;
}

$enable-negative-margins: true;
$font-family-base: $main-font;

@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons';

@import 'styles/utils.scss';
@import 'styles/header.scss';
@import 'styles/footer.scss';

@import 'styles/imageSeparator.scss';
@import 'styles/aboutCenter.scss';
@import 'styles/teachers.scss';
@import 'styles/support.scss';
@import 'styles/carousel';
